import React, { FC } from 'react';

import { InvoiceDetailsDto } from '@hofy/api-admin';
import { Box, FormContainer } from '@hofy/ui';

import { InvoiceAccountingSection } from './components/InvoiceAccountingSection';
import { InvoiceCommunicationsSection } from './components/InvoiceCommunicationSection';
import { InvoiceDeelSection } from './components/InvoiceDeelSection';
import { InvoiceDetailsSection } from './components/InvoiceDetailsSection';
import { InvoiceStatusHistorySection } from './components/InvoiceStatusHistorySection';

interface InvoiceDetailsTabProps {
    invoice: InvoiceDetailsDto;
}

export const InvoiceDetailsTab: FC<InvoiceDetailsTabProps> = ({ invoice }) => {
    return (
        <Box fullHeight row alignItems='flex-start' overflow='auto' paddingHorizontal={40}>
            <FormContainer flex='auto' marginVertical={30} marginRight={20}>
                <InvoiceDetailsSection invoice={invoice} />
                <InvoiceCommunicationsSection notifications={invoice.notification} />
                <InvoiceStatusHistorySection
                    statusHistory={invoice.statusHistory}
                    isCreditNote={invoice.isCreditNote}
                />
                <InvoiceDeelSection deel={invoice.deel} />
                <InvoiceAccountingSection
                    netsuite={invoice.netsuite}
                    revenueRecognitionGenerated={invoice.revenueRecognitionGenerated}
                />
            </FormContainer>
        </Box>
    );
};
