import { Response } from '@hofy/global';

interface ErrorResponse {
    message: string | null;
    code: string;
}

export class ResponseError extends Error {
    public response: ErrorResponse | null;
    public status: number = 0;
    public handled: boolean = false;

    public static readonly create = async (response: Response) => {
        try {
            const body = await response.json();
            return new ResponseError(response, {
                code: body.code ?? null,
                message: body.message ?? null,
            });
        } catch (e) {
            return new ResponseError(response);
        }
    };

    public constructor(
        public originalResponse: Response,
        data?: ErrorResponse,
    ) {
        super(
            `status=${originalResponse.status}|url=${originalResponse.url}|code=${data?.code}|message=${data?.message}`,
        );
        this.name = 'ResponseError';
        this.response = data || null;
        this.status = originalResponse.status;
    }
}

export const isResponseError = (arg: any): arg is ResponseError => {
    return arg.name === 'ResponseError';
};

export const isNotFoundError = (e: unknown): e is ResponseError => isResponseError(e) && e.status === 404;
