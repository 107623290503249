export enum UserError {
    PerSeatAccess = 'per_seat_access_error',
    InvalidStartDate = 'invalid_start_date',
    DuplicateUser = 'duplicate_user',
    EmailExists = 'another_user_with_this_email_already_exists',
    InvalidMergeRequest = 'invalid_merge_request',
    MissingDeelLink = 'missing_deel_link',
    IncorrectDeelOrganization = 'incorrect_deel_organization',
    UserNotOffboarded = 'user_is_not_offboarded',
    UserBoundToActiveAssignment = 'user_is_still_bound_to_an_active_assignment',
    InvalidFileForUsersUpload = 'invalid_file_for_users_upload',
    InvalidSheetsCountForUsersUpload = 'invalid_sheets_count_for_users_upload',
    InvalidHeaderForUsersUpload = 'invalid_headers_for_users_upload',
    MissingTeam = 'missing_team',
    MissingFirstName = 'missing_first_name',
    MissingLastName = 'missing_last_name',
    MissingEmails = 'missing_emails',
    MissingCountry = 'missing_country',
    InvalidTeam = 'invalid_team',
    InvalidLoginEmailType = 'invalid_login_email_type',
    InvalidCountry = 'invalid_country',
    InvalidNewJoinerStartDate = 'invalid_new_joiner_start_date',
    EmailExist = 'email_exists',
}
