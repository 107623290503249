import { invert, kebabCase } from 'lodash-es';

export type EnumValue = string | number;

interface AnyStringEnum {
    [index: string]: EnumValue;
}

interface AnyNumericEnum {
    [index: number]: EnumValue;
}

export type AnyEnum = AnyStringEnum | AnyNumericEnum;

export const getEnumValues = <T extends EnumValue>(e: any): T[] => {
    return Object.keys(e)
        .filter(key => isNaN(+key))
        .map(name => e[name]) as T[];
};

export const enumToLabel =
    <T extends AnyEnum>(enumEntity: T, prefix: string) =>
    (enumValue: EnumValue | null): string => {
        // eslint-disable-next-line sonarjs/different-types-comparison
        if (enumValue === null || enumValue === undefined) {
            return 'general.text.empty';
        }
        if (prefix.includes('{value}')) {
            return prefix.replace('{value}', enumToName(enumEntity)(enumValue));
        }
        return `${prefix}.${enumToName(enumEntity)(enumValue)}`;
    };

const enumToName =
    <T extends AnyEnum>(enumEntity: T) =>
    (enumValue: EnumValue): string =>
        kebabCase(getEnumName(enumEntity)(enumValue));

const getEnumName =
    <T extends AnyEnum>(enumEntity: T) =>
    (enumValue: EnumValue): string => {
        if (typeof enumValue === 'string') {
            return (invert(enumEntity) as any)[enumValue as any];
        }
        return (enumEntity as any)[enumValue];
    };

export const isValidEnumEntry =
    <T extends AnyEnum>(enumEntity: T) =>
    (enumValue: any): enumValue is T => {
        if (!enumValue || (typeof enumValue !== 'string' && typeof enumValue !== 'number')) {
            return false;
        }
        // eslint-disable-next-line sonarjs/different-types-comparison
        return getEnumName(enumEntity)(enumValue) !== undefined;
    };
