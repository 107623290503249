import React, { FC } from 'react';

import { DeelStatusDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { Box, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { DeelStatusChip } from '../../../../../components/domain/invoices/DeelStatusChip';
import { invoiceLink, LabeledDeelLink } from '../../../../../components/routing/LabeledDeelLink';

interface InvoiceDeelSectionProps {
    deel: DeelStatusDto;
}

export const InvoiceDeelSection: FC<InvoiceDeelSectionProps> = ({ deel }) => {
    if (!deel.status) {
        return;
    }
    return (
        <FormSection label='Deel'>
            <Box row>
                <Box column flex={4} rowGap={24}>
                    <FormGridRow columns={3}>
                        <LabeledDeelLink label='Id' id={deel.id} link={invoiceLink} />
                        <Labeled label='Status' content={<DeelStatusChip status={deel} />} />
                        {deel.successAt && (
                            <LabeledText label='Success at' content={formatDateTime(deel.successAt)} />
                        )}
                        {deel.errorAt && (
                            <LabeledText label='Error at' content={formatDateTime(deel.errorAt)} />
                        )}
                        {deel.errorReason && <LabeledText label='Error reason' content={deel.errorReason} />}
                    </FormGridRow>
                </Box>
                <Box row flex={1} minWidth='200px' />
            </Box>
        </FormSection>
    );
};
