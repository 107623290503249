import { min } from 'lodash-es';
import { DateTime, Interval } from 'luxon';

import { AnyDateString, DateRangeStrings, DateRangeType, DateString, getEnumValues } from '@hofy/global';

export const allDateRangeTypes = getEnumValues<DateRangeType>(DateRangeType);

export const dateRangeToRangeType = (range: DateRangeStrings): DateRangeType => {
    if (range.from && range.to) {
        return DateRangeType.FromToDate;
    }
    if (range.from) {
        return DateRangeType.FromDate;
    }
    if (range.to) {
        return DateRangeType.ToDate;
    }
    throw new Error('[dateRangeToRangeType] Invalid date range, must have either from or to or both');
};

/** Mostly used for different date picker types */
export type DateType = 'day' | 'month';

const defaultDateFormat = 'dd MMM yyyy';
const defaultMonthFormat = 'MMMM yyyy';
const defaultDateTimeFormat = "dd MMM yyyy 'at' HH:mm";
const defaultTimeFormat = "'at' HH:mm";
const defaultEmptyFormattedDate = '--';
const dateRangeSeparator = ' - ';

/** List of supported date formats for parsing user input */
const supportedFormats = [
    // full date
    defaultDateFormat,
    'dd MM yyyy',
    'dd M yyyy',
    'd MMM yyyy',
    'dd/MM/yyyy',
    'd/MM/yyyy',
    'd/M/yyyy',
    'yyyy-MM-dd',
    'dd-MM-yyyy',

    // month with year
    defaultMonthFormat,
    'MMM yyyy',
    'MM yyyy',
    'M yyyy',
    'yyyy-MM',
    'yyyy/MM',
    'MM-yyyy',
    'M-yyyy',
];
type NullableDateInputType = AnyDateString | DateTime | null;
type DateInputType = AnyDateString | DateTime;
/**
 * Formats the provided string or DateTime object as a plain date.
 */
export const formatDate = (date: NullableDateInputType, format = defaultDateFormat): string => {
    if (!date) {
        return defaultEmptyFormattedDate;
    }
    if (typeof date === 'string') {
        return parseDateTime(date).toFormat(format);
    }
    return date.toUTC().toFormat(format);
};

/**
 * Formats the provided string or DateTime object as a month date.
 */
export const formatMonth = (date: NullableDateInputType): string => {
    return formatDateTime(date, defaultMonthFormat);
};
export const formatApiMonth = (date: NullableDateInputType): string => {
    return formatDateTime(date, 'yyyy-MM');
};

/**
 * Formats the provided string or DateTime object as a full date and time string in local timezone.
 */
export const formatDateTime = (date: NullableDateInputType, format = defaultDateTimeFormat): string => {
    if (!date) {
        return defaultEmptyFormattedDate;
    }
    if (typeof date === 'string') {
        return DateTime.fromISO(date).toFormat(format);
    }

    return date.toFormat(format);
};

/**
 * Formats the provided string or DateTime object as a string with the time in local timezone.
 */
export const formatTime = (date: NullableDateInputType): string => {
    return formatDateTime(date, defaultTimeFormat);
};

/**
 * Formats the provided string or DateTime object as a relative time string.
 * eg.: "4 hours ago", "17 days ago"
 *
 * @param date string | DateTime
 * @returns string | null
 */
export const formatRelative = (date: DateInputType): string | null => {
    if (typeof date === 'string') {
        return DateTime.fromISO(date).toRelative();
    }

    return date.toRelative();
};

/**
 * Parse a string to a DateTime object with UTC timezone.
 */
export const parseDateTime = (date: AnyDateString): DateTime => {
    return DateTime.fromISO(date, { zone: 'UTC' }).toUTC();
};

/**
 * Parse an optional string to a DateTime object with UTC timezone.
 */
export const parseOptionalDateTime = (date: AnyDateString | null | undefined): DateTime | null => {
    if (date) {
        return parseDateTime(date);
    }
    return null;
};

/**
 * Parse a user input date string with multiple formats to a DateTime.
 */
export const parseUserDate = (date: string): DateString | null => {
    const shortestFormat = min(supportedFormats.map(format => format.length))!;

    if (date.length < shortestFormat) {
        return null;
    }

    const parsedDate = parseDateTime(date as DateString);

    if (parsedDate.isValid) {
        return toISODate(parsedDate);
    }

    for (const format of supportedFormats) {
        const parsedDate = DateTime.fromFormat(date, format);
        if (parsedDate.isValid) {
            return toISODate(parsedDate);
        }
    }

    return null;
};

export const formatDateRange = (range: DateRangeStrings | null, format = defaultDateFormat): string => {
    if (!range) {
        return '';
    }

    if (range.from && range.to) {
        return [formatDate(range.from, format), formatDate(range.to, format)].join(dateRangeSeparator);
    }

    if (range.from) {
        return formatDate(range.from, format);
    }

    if (range.to) {
        return formatDate(range.to, format);
    }

    throw new Error('[formatDateRange] Invalid date range, must have either from or to or both');
};

export const formatMonthRange = (range: DateRangeStrings | null): string => {
    return formatDateRange(range, defaultMonthFormat);
};

export const parseDateRange = (range: string): DateRangeStrings | null => {
    const [fromString, toString] = range.split(dateRangeSeparator);
    if (!fromString || !toString) {
        return null;
    }
    const from = parseUserDate(fromString);
    const to = parseUserDate(toString);

    if (!from || !to) {
        return null;
    }

    return sortDateRange({ from, to });
};

export const sortDateRange = (range: DateRangeStrings): DateRangeStrings => {
    if (range.from && range.to && isAfter(range.from, range.to)) {
        return { from: range.to, to: range.from };
    }
    return range;
};

/** @deprecated Use `formatDateRange` instead */
export const formatTwoDates = (
    from: AnyDateString | null,
    to: AnyDateString | null,
    format = defaultDateFormat,
) => {
    if (!from && !to) {
        return defaultEmptyFormattedDate;
    }
    const dates = [];
    if (from) {
        dates.push(formatDate(from, format));
    }
    if (to) {
        dates.push(formatDate(to, format));
    }

    return dates.join(' - ');
};

/**
 * Helpers for date manipulation.
 */

const toDateTime = (d: DateInputType): DateTime => (typeof d === 'string' ? parseDateTime(d) : d);

export const toISODate = (date: DateTime): DateString => {
    const dateString = date.toISODate();
    if (!dateString) {
        throw new Error('Invalid date');
    }
    return dateString as DateString;
};

export const isAfter = (date: DateInputType, otherDate: DateInputType) => {
    return toDateTime(date).startOf('day') > toDateTime(otherDate).startOf('day');
};

export const isBetween = (date: DateInputType, startDate: DateInputType, endDate: DateInputType) => {
    const actualDate = toDateTime(date).startOf('day');
    const isBeforeEndDate = actualDate < toDateTime(endDate).endOf('day');
    const isAfterOrEqualStartDate = actualDate >= toDateTime(startDate).startOf('day');
    return isBeforeEndDate && isAfterOrEqualStartDate;
};

export const diffDays = (startDate: DateInputType, endDate: DateInputType) => {
    const start = toDateTime(startDate).startOf('day');
    const end = toDateTime(endDate).startOf('day');

    if (start <= end) {
        return Interval.fromDateTimes(start, end).length('days');
    }
    // eslint-disable-next-line sonarjs/arguments-order
    return Interval.fromDateTimes(end, start).length('days') * -1;
};

export const diffWorkingDays = (startDate: DateTime | DateString, endDate: DateTime | DateString): number => {
    let start = toDateTime(startDate).startOf('day');
    let end = toDateTime(endDate).startOf('day');

    if (start > end) {
        [start, end] = [end, start];
    }

    let workingDays = 0;
    let currentDate = start;

    while (currentDate < end) {
        if (isWorkingDay(currentDate)) {
            workingDays++;
        }
        currentDate = currentDate.plus({ days: 1 });
    }

    return workingDays;
};

export const isDateInPast = (t: DateInputType): boolean => {
    return diffDays(now(), toDateTime(t)) < 0;
};

export const isDateInFuture = (t: DateInputType): boolean => {
    return diffDays(now(), toDateTime(t)) > 0;
};

export const isDateToday = (t: DateInputType): boolean => {
    return diffDays(now(), toDateTime(t)) === 0;
};

export const isDateTomorrow = (t: DateInputType): boolean => {
    return diffDays(now().plus({ days: 1 }), toDateTime(t)) === 0;
};

export const isDateCurrentMonth = (date: DateInputType) => {
    return diffDays(endOfMonth(), dateTimeToDate(toDateTime(date).endOf('month'))) === 0;
};

export const isEndOfMonth = () => {
    return diffDays(endOfMonth(), nowDate()) === 0;
};

const dateTimeToDate = (d: DateTime) => DateTime.utc(d.year, d.month, d.day);

export const now = (): DateTime => DateTime.now().toUTC();

export const nowDate = (): DateTime => dateTimeToDate(now());

export const endOfMonth = (): DateTime => dateTimeToDate(now().endOf('month'));
export const startOfMonth = (): DateTime => dateTimeToDate(now().startOf('month'));

export const endOfPreviousMonth = (): DateTime => dateTimeToDate(now().minus({ months: 1 }).endOf('month'));

export const isWorkingDay = (date: DateTime): boolean => {
    return date.weekday >= 1 && date.weekday <= 5;
};

export const nowISODate = (): DateString => toISODate(nowDate());

const getTomorrow = (): DateTime => now().plus({ days: 1 });
export const getTomorrowISODate = (): DateString => toISODate(getTomorrow());

export const addWorkingDays = (startDate: DateTime, addedWorkingDays: number): DateTime => {
    let resultDate = startDate;

    while (addedWorkingDays > 0) {
        resultDate = resultDate.plus({ days: 1 });

        if (isWorkingDay(resultDate)) {
            addedWorkingDays--;
        }
    }

    return resultDate;
};

export const subtractWorkingDays = (startDate: DateTime, subtractedWorkingDays: number): DateTime => {
    let resultDate = startDate;

    while (subtractedWorkingDays > 0) {
        resultDate = resultDate.minus({ days: 1 });

        if (isWorkingDay(resultDate)) {
            subtractedWorkingDays--;
        }
    }

    return resultDate;
};

/**
 * Utilities for JS Date <-> Luxon DateTime conversion.
 */

export const jsDateToLuxonDate = (d: Date) => DateTime.utc(d.getFullYear(), d.getMonth() + 1, d.getDate());
export const luxonToJsDate = (d: DateTime) => new Date(d.year, d.month - 1, d.day);
export const optionalLuxonToJsDate = (d?: DateTime | null) => (d ? luxonToJsDate(d) : null);
export const dateStringToJsDate = (d: DateString) => luxonToJsDate(parseDateTime(d));
export const optionalDateStringToJsDate = (d?: DateString | null) =>
    d ? luxonToJsDate(parseDateTime(d)) : null;
