export const isEmpty = (t: any): boolean => t === '' || t === undefined || t === null;

export const isNotEmpty = (t: any): boolean => !isEmpty(t);

const emailPattern =
    // eslint-disable-next-line sonarjs/regex-complexity
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isValidRegex = (t: string | undefined, r: RegExp): boolean => !!t && r.test(t);

export const isInvalidRegex = (t: string | undefined, r: RegExp) => !isValidRegex(t, r);

export const isValidHTTPSURL = (urlParam: string | null) => {
    let url;

    try {
        url = new URL(urlParam ?? '');
    } catch (_) {
        return false;
    }

    return url.protocol === 'https:';
};

/**
 * Empty email is considered valid, should be checked with isEmpty
 * @param email
 */
export const isValidEmail = (email?: string | null) => {
    return email !== undefined && email !== null && email !== '' ? emailPattern.test(email) : true;
};

export const isInvalidEmail = (email: string | null) => {
    return !isValidEmail(email);
};

export const isPhoneNumberTooLong = (phoneNumber: string | undefined | null) => {
    return !!phoneNumber && phoneNumber.length > 15;
};

export const isAtLeast128bit = (v: string | undefined) => {
    const encoder = new TextEncoder();
    const bytes = encoder.encode(v);

    return bytes.byteLength >= 16;
};
