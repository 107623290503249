import * as Sentry from '@sentry/react';

import { APP_CONFIG, GIT_HASH } from '@hofy/config';

export const initSentry = () => {
    if (APP_CONFIG.sentryDsn) {
        Sentry.init({
            dsn: APP_CONFIG.sentryDsn,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 0,
            release: GIT_HASH,
            environment: APP_CONFIG.env,
        });
    }
};
