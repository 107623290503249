import React, { FC } from 'react';

import { BulkStorageOrderItemDto, useBulkStorageOrderQuery } from '@hofy/api-admin';
import { ComponentLoader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, ErrorStatePlaceholder, FormSection } from '@hofy/ui';

import { BulkStorageOrderDetails } from './BulkStorageOrderDetails';
import { BulkStorageOrderItemBox } from './BulkStorageOrderItemBox';

interface BulkStorageOrderItemsScanSelectProps {
    bulkStorageOrderId: UUID;
    setBulkStorageOrderItem(bulkStorageOrderItemDto: BulkStorageOrderItemDto): void;
}

export const BulkStorageOrderItemsScanSelect: FC<BulkStorageOrderItemsScanSelectProps> = ({
    bulkStorageOrderId,
    setBulkStorageOrderItem,
}) => {
    const { data: bulkStorageOrder, isLoading, isError } = useBulkStorageOrderQuery(bulkStorageOrderId);

    if (isLoading) {
        return <ComponentLoader />;
    }

    if (isError || !bulkStorageOrder) {
        return <ErrorStatePlaceholder />;
    }
    const itemRows = bulkStorageOrder.items
        .toSorted((a, b) => b.reservedQuantity - a.assignedQuantity)
        .map(item => (
            <BulkStorageOrderItemBox
                key={item.id}
                item={item}
                setBulkStorageOrderItem={setBulkStorageOrderItem}
            />
        ));

    return (
        <Box marginVertical={16}>
            <FormSection label='Details' marginVertical={16}>
                <BulkStorageOrderDetails order={bulkStorageOrder} />
            </FormSection>
            <FormSection label='Products'>{itemRows}</FormSection>
        </Box>
    );
};
