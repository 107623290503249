import React, { FC } from 'react';

import { Alert, Box, Form, FormInput, SubmitButton } from '@hofy/ui';

import { useSeedDemoOrganization } from '../../../../store/settings/useSeedDemoOrganization';

export const SeedDemoOrganization: FC = () => {
    const { form, isLoading } = useSeedDemoOrganization();

    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert
                    type='informative'
                    description='Create an organization for sales demos. Includes teams with users with devices'
                />
                <FormInput label='Organization Name' api={form.fields.organizationName} />
                <SubmitButton label='Generate Demo Org' />
            </Form>
        </Box>
    );
};
