import { useCallback, useEffect, useState } from 'react';

import { STORAGE_LOCAL_EVENT } from '@hofy/helpers';

// event for same browser card (storage event is cross card)

export const useLocalStorage = <T>(
    key: string,
    initialValue: T,
    readTransformer?: (v: T) => T,
): [T, (value: T) => void] => {
    const readValue = () => {
        try {
            const item = window.localStorage.getItem(key);
            if (!item) {
                return initialValue;
            }
            const parsedValue = JSON.parse(item);
            if (readTransformer) {
                return readTransformer(parsedValue);
            }
            return parsedValue;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    };

    const [storedValue, setStoredValue] = useState<T>(readValue);

    const setValue = useCallback((value: T) => {
        try {
            const newValue = value instanceof Function ? value(storedValue) : value;
            window.localStorage.setItem(key, JSON.stringify(newValue));
            setStoredValue(newValue);
            window.dispatchEvent(new Event(STORAGE_LOCAL_EVENT));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`Error setting localStorage key “${key}”:`, error);
        }
    }, []);

    useEffect(() => {
        setStoredValue(readValue);
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            setStoredValue(readValue);
        };

        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('local-storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('local-storage', handleStorageChange);
        };
    }, []);

    return [storedValue, setValue];
};
