import { isNil } from 'lodash-es';

import { Response, RestClient } from '@hofy/global';

export type RequestInterceptorParam = [string, RequestInit];

type RequestInterceptor = (
    r: RequestInterceptorParam,
) => RequestInterceptorParam | Promise<RequestInterceptorParam>;

type ResponseInterceptor = (r: Response) => Promise<Response>;

const contentType = 'Content-Type';

export class RestClientImpl implements RestClient {
    private responseInterceptors: ResponseInterceptor[] = [];
    private requestInterceptors: RequestInterceptor[] = [];
    private baseUrl: string;

    public constructor(
        baseUrl: string,
        requestInterceptors: RequestInterceptor[],
        responseInterceptors: ResponseInterceptor[],
    ) {
        this.responseInterceptors = responseInterceptors;
        this.requestInterceptors = requestInterceptors;
        this.baseUrl = baseUrl;
    }

    public addRequestInterceptor = (r: RequestInterceptor) => {
        this.requestInterceptors.push(r);
    };

    public static readonly errorHandler: (r: Error) => Promise<Error> = e => {
        return Promise.reject(e);
    };

    public static jsonTransformer<T>(r: Response): T {
        return r.json() as any;
    }

    public static blobTransformer(r: Response): Promise<Blob> {
        return r.blob().then(b => new Blob([b], { type: r.headers.get(contentType) || 'text/plain' }));
    }

    public static fileDownloadTransformer(r: Response): Promise<[Blob, string]> {
        return RestClientImpl.blobTransformer(r).then(b => {
            const filename = RestClientImpl.getFilenameFromHeader(r.headers.get('Content-Disposition'));
            if (filename) {
                return [b, filename] as [Blob, string];
            }
            throw new Error('missing filename in download response');
        });
    }

    private static getFilenameFromHeader = (headerValue: string | null) => {
        if (headerValue?.includes('attachment')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(headerValue);

            if (!isNil(matches) && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }
        return undefined;
    };

    public getBlob = (url: string, payload?: any, headers?: Headers): Promise<any> =>
        this.request(url, {
            method: 'GET',
            body: payload,
            headers: headers || new Headers(),
        }).then(RestClientImpl.blobTransformer);

    public post = (url: string, payload?: any, headers?: Headers): Promise<void> =>
        this.request(url, {
            method: 'POST',
            body: payload,
            headers: headers || new Headers(),
        }).then();

    public delete = (url: string, headers?: Headers): Promise<void> =>
        this.request(url, {
            method: 'DELETE',
            headers: headers || new Headers(),
        }).then();

    public patch = (url: string, payload?: any, headers?: Headers): Promise<void> =>
        this.request(url, {
            method: 'PATCH',
            body: payload,
            headers: headers || new Headers(),
        }).then();

    public put = (url: string, payload?: any, headers?: Headers): Promise<void> =>
        this.request(url, {
            method: 'PUT',
            body: payload,
            headers: headers || new Headers(),
        }).then();
    public patchJson = <T>(url: string, payload?: any, headers?: Headers): Promise<T> =>
        this.request(url, {
            method: 'PATCH',
            body: payload,
            headers: this.addJsonHeaders(headers),
        }).then(RestClientImpl.jsonTransformer<T>);

    public putJson = <T>(url: string, payload?: any, headers?: Headers): Promise<T> =>
        this.request(url, {
            method: 'PUT',
            body: payload,
            headers: this.addJsonHeaders(headers),
        }).then(RestClientImpl.jsonTransformer<T>);

    public postJson = <T>(url: string, payload?: any, headers?: Headers): Promise<T> =>
        this.request(url, {
            method: 'POST',
            body: payload,
            headers: this.addJsonHeaders(headers),
        }).then(RestClientImpl.jsonTransformer<T>);

    public getJson = <T>(url: string, headers?: Headers): Promise<T> =>
        this.request(url, {
            method: 'GET',
            headers: this.addJsonHeaders(headers),
        }).then(RestClientImpl.jsonTransformer<T>);

    public downloadFile = (url: string): Promise<[Blob, string]> =>
        this.request(url, {
            method: 'GET',
        }).then(RestClientImpl.fileDownloadTransformer);

    private request = (url: string, init: RequestInit = {}): Promise<Response> =>
        this.getRequestInterceptors(url, init)
            .then(([nextUrl, nextInit]: RequestInterceptorParam) =>
                fetch(`${this.baseUrl}${nextUrl}`, nextInit),
            )
            .then(this.getResponseInterceptors)
            .catch(RestClientImpl.errorHandler) as Promise<Response>;

    private getResponseInterceptors = (r: Response): Promise<Response> => {
        let current = Promise.resolve(r);
        this.responseInterceptors.forEach(i => {
            current = current.then(i);
        });
        return current;
    };

    private getRequestInterceptors = (url: string, init: RequestInit): Promise<RequestInterceptorParam> => {
        let current = Promise.resolve([url, init] as RequestInterceptorParam);
        this.requestInterceptors.forEach(i => {
            current = current.then(i);
        });
        return current;
    };

    private addJsonHeaders = (headers?: Headers): Headers => {
        const newHeaders = headers || new Headers();
        newHeaders.append(contentType, 'application/json');
        return newHeaders;
    };
}
