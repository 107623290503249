import React, { FC } from 'react';

import { UserContactPreferenceDto } from '@hofy/api-admin';
import { Box, FeaturedIcon, FormGridRow, Paragraph3, SvgIcon } from '@hofy/ui';

import { SurveyResultsSectionCard } from '../surveyResultsSection/SurveyResultsSectionCard';

interface ContactPreferenceSectionProps {
    contactPreference: UserContactPreferenceDto;
}

export const ContactPreferenceSection: FC<ContactPreferenceSectionProps> = ({ contactPreference }) => {
    return (
        <SurveyResultsSectionCard fullWidth border alignSelf='stretch' padding={16}>
            <FormGridRow columns={1}>
                <ContactPreferenceChip contactType='Work email' contactValue={contactPreference.workEmail} />
                <ContactPreferenceChip
                    contactType='Personal email'
                    contactValue={contactPreference.personalEmail}
                />
                <ContactPreferenceChip
                    contactType='Other contact email'
                    contactValue={contactPreference.otherEmail}
                />
            </FormGridRow>
        </SurveyResultsSectionCard>
    );
};

interface ContactPreferenceChipProps {
    contactType: string;
    contactValue: string | null;
}

const ContactPreferenceChip: FC<ContactPreferenceChipProps> = ({ contactType, contactValue }) => {
    return (
        <Box row gap={12}>
            <ContactPreferenceChipIcon isPreferredContact={!!contactValue} />
            <Box column>
                <Paragraph3>{contactType}</Paragraph3>
                {contactValue && <Paragraph3>{contactValue}</Paragraph3>}
            </Box>
        </Box>
    );
};

const ContactPreferenceChipIcon: FC<{ isPreferredContact: boolean }> = ({ isPreferredContact }) => {
    if (isPreferredContact) {
        return <FeaturedIcon icon={SvgIcon.Check} type='positive' />;
    }
    return <FeaturedIcon icon={SvgIcon.Cross} type='negative' />;
};
