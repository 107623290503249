import { isEqual, isNil, isString, omitBy, toString } from 'lodash-es';
import { parse, stringifyUrl } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DateRangeStrings } from '@hofy/global';

const isEmpty = (v: any): boolean => {
    return v === undefined || v === null || v === '';
};

type ParsedQueryParam<T = string> = T | T[] | null;
type StateDef<T> = [T, (v: T) => void];
type Changer = 'replace' | 'push';

/** @deprecated use `useFilters` from `@hofy/ui` or `useQueryParams` instead from `@hofy/router` */
export const useQueryParam = <T>(
    name: string,
    initialValue: T,
    parser = (v: ParsedQueryParam<string | boolean | number | T | null>) => v,
    serializer = (v: T): string | null => v as any,
    changer: Changer = 'replace',
    parseAsString: boolean = false,
    // eslint-disable-next-line @typescript-eslint/max-params
) => {
    const history = useHistory();
    const queryParams = useMemo(
        () =>
            parse(history.location.search, {
                parseBooleans: !parseAsString && typeof initialValue !== 'string',
                parseNumbers: !parseAsString && typeof initialValue !== 'string',
            }),
        [history.location.search, parseAsString, initialValue],
    );
    const [value, setValue] = useState<T | null>(() =>
        isEmpty(queryParams[name]) ? (initialValue ?? null) : (parser(queryParams[name]) as T),
    );

    useEffect(() => {
        const replaceParams = (p: Record<string, any>) => {
            const newURL = stringifyUrl({
                url: history.location.pathname,
                query: omitBy(p, isEmpty),
            });
            history[changer](newURL);
        };

        if (isNil(value) || isEqual(value, initialValue)) {
            // eslint-disable-next-line sonarjs/different-types-comparison
            if (queryParams[name] !== undefined) {
                replaceParams({
                    ...queryParams,
                    [name]: null,
                });
            }
            return;
        }

        if (!isEqual(parser(queryParams[name]), value)) {
            replaceParams({
                ...queryParams,
                [name]: serializer(value),
            });
        }
    }, [history, queryParams, value, initialValue, name, serializer, parser, changer]);

    return [value, setValue] as StateDef<T>;
};

interface UseNumberQueryParam {
    (name: string, initialValue: number): StateDef<number>;
    (name: string, initialValue?: number): StateDef<number | null>;
}

/** @deprecated use `useFilters` from `@hofy/ui` or `useQueryParams` instead from `@hofy/router` */
export const useNumberQueryParam: UseNumberQueryParam = (
    name: string,
    initialValue?: number,
): StateDef<any> => useQueryParam(name, initialValue);

interface UseStringQueryParam {
    <T extends string>(name: string, initialValue: T): StateDef<T>;
    <T extends string>(name: string, initialValue?: T): StateDef<T | null>;
}

/** @deprecated use `useFilters` from `@hofy/ui` or `useQueryParams` instead from `@hofy/router` */
export const useStringQueryParam: UseStringQueryParam = (
    name: string,
    initialValue?: string,
): StateDef<any> => useQueryParam(name, initialValue, toString, undefined, undefined, true);

interface UseBooleanQueryParam {
    (name: string, initialValue: boolean): StateDef<boolean>;
    (name: string, initialValue?: boolean): StateDef<boolean | null>;
}

/** @deprecated use `useFilters` from `@hofy/ui` or `useQueryParams` instead from `@hofy/router` */
export const useBooleanQueryParam: UseBooleanQueryParam = (
    name: string,
    initialValue?: boolean,
): StateDef<any> => useQueryParam(name, initialValue);

/** @deprecated use `useFilters` from `@hofy/ui` or `useQueryParams` instead from `@hofy/router` */
export const useArrayQueryParam = <T extends any>(name: string, initialValue = [] as T[]) =>
    useQueryParam(name, initialValue, value => {
        if (isEmpty(value)) {
            return [];
        }
        return (Array.isArray(value) ? value : [value]) as T[];
    });

const PARAM_SEPARATOR = ' ';

/** @deprecated use `useFilters` from `@hofy/ui` or `useQueryParams` instead from `@hofy/router` */
export const useDateRangeQueryParam = (name: string, initialValue: DateRangeStrings | null = null) => {
    return useQueryParam<DateRangeStrings | null>(
        name,
        initialValue,
        value => {
            if (!isString(value)) {
                return null;
            }
            const [from, to] = value.split(PARAM_SEPARATOR);
            return {
                from,
                to,
            } as DateRangeStrings;
        },
        value => {
            if (!value) {
                return null;
            }
            return [value.from, value.to].join(PARAM_SEPARATOR);
        },
    );
};
