import React, { forwardRef, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { renderTextNode } from '../../../helpers/React';
import { TestKeyAware } from '../../../types';
import { Box, BoxProps, Paragraph3 } from '../../base';
import { Checkbox } from '../../form/checkbox/Checkbox';

interface DropdownInteractiveListItemProps extends BoxProps, TestKeyAware {
    isActive: boolean;
    isSelected: boolean;
    label: ReactNode;
    checkbox?: boolean;
}

export const DropdownInteractiveListItem = forwardRef<HTMLDivElement, DropdownInteractiveListItemProps>(
    ({ isActive, isSelected, label, checkbox, testKey, ...rest }, ref) => {
        const selectedBg = checkbox ? undefined : Color.InteractionGhostActive;
        const activeBg = isActive ? Color.InteractionGhostHover : undefined;
        const bg = isSelected && selectedBg ? selectedBg : activeBg;

        return (
            <Box
                ref={ref}
                role='option'
                pointer
                paddingHorizontal={16}
                paddingVertical={8}
                tabIndex={isActive ? 0 : -1}
                aria-selected={isSelected && isActive}
                row
                gap={8}
                rounded={8}
                bg={bg}
                data-test-key={testKey}
                {...rest}
            >
                {checkbox && <Checkbox checked={isSelected} />}
                {renderTextNode(label, label => (
                    <Paragraph3 textNoWrap overflow='hidden' ellipsis color={Color.ContentPrimary}>
                        {label}
                    </Paragraph3>
                ))}
            </Box>
        );
    },
);
